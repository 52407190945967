var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-a"},[_c('van-form',{on:{"submit":_vm.Add}},[_c('div',{staticClass:"u-img"},[_c('div',[_c('van-uploader',{attrs:{"upload-icon":"plus","before-read":_vm.beforeRead,"after-read":_vm.afterRead,"max-size":10 * 1024 * 1024,"capture":_vm.camera},on:{"oversize":_vm.onOversize},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}}),(_vm.fileList.length)?_c('div',[_c('img',{attrs:{"src":_vm.fileList,"alt":""}})]):_vm._e()],1),_c('div',[_vm._v("(上传照片)")])]),_c('div',{staticClass:"info-block"},[_c('van-row',{staticClass:"i-title",attrs:{"type":"flex","align":"center","justify":"space-between"}},[_c('van-col',[_c('span',{staticClass:"info-block-title"},[_vm._v("访问人基本信息")])])],1),_c('van-field',{attrs:{"required":"","label":"访问人姓名","placeholder":"填写访问人姓名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('van-field',{attrs:{"label":"手机号码","required":"","placeholder":"填写手机号码","rules":[
                {
                  validator: function (value) { return this$1.defineMethods.VerifyMobile(value); },
                  message: '请填写正确手机号码',
                } ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"label":"身份证号","required":"","placeholder":"填写身份证号","rules":[
                {
                  validator: function (value) { return this$1.defineMethods.IsIDCard(value); },
                  message: '请填写正确身份证号',
                } ]},model:{value:(_vm.form.IDNumber),callback:function ($$v) {_vm.$set(_vm.form, "IDNumber", $$v)},expression:"form.IDNumber"}}),_c('van-field',{attrs:{"label":"到访时间","required":"","right-icon":"arrow","readonly":"","placeholder":"填写到访时间"},on:{"click":function($event){_vm.showTime = true}},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('van-field',{attrs:{"rows":"1","autosize":{ minHeight: 88 },"required":"","type":"textarea","label":"到访原因","placeholder":"填写到访原因"},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1),_c('div',{staticClass:"info-block"},[_c('van-row',{staticClass:"i-title",attrs:{"type":"flex","align":"center","justify":"space-between"}},[_c('van-col',[_c('span',{staticClass:"info-block-title"},[_vm._v("被访人信息")])])],1),_c('van-field',{attrs:{"required":"","label":"被访人姓名","placeholder":"填写被访人姓名"},model:{value:(_vm.form.vName),callback:function ($$v) {_vm.$set(_vm.form, "vName", $$v)},expression:"form.vName"}}),_c('van-field',{attrs:{"label":"被访人电话","required":"","placeholder":"填写被访人手机号码","rules":[
                {
                  validator: function (value) { return this$1.defineMethods.VerifyMobile(value); },
                  message: '请填写正确手机号码',
                } ]},model:{value:(_vm.form.vNumber),callback:function ($$v) {_vm.$set(_vm.form, "vNumber", $$v)},expression:"form.vNumber"}})],1),_c('div',{staticClass:"a-btn"},[_c('van-button',{attrs:{"type":"primary","disabled":_vm.isEmpty,"color":_vm.defineStyle.themeColor,"block":"","native-type":"submit"}},[_vm._v("确认")]),_c('van-button',{attrs:{"type":"primary","color":_vm.defineStyle.color.gray,"block":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")])],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTime),callback:function ($$v) {_vm.showTime=$$v},expression:"showTime"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"选择年月日"},on:{"confirm":_vm.onConfirmTime,"cancel":function($event){_vm.showTime = false}},model:{value:(_vm.chooseTime),callback:function ($$v) {_vm.chooseTime=$$v},expression:"chooseTime"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }