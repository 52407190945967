<template>
	<div class="add-a">
		<van-form @submit="Add">
		<div class="u-img">
			<div>
				<van-uploader
					v-model="fileList"
					upload-icon="plus"
					:before-read="beforeRead"
					:after-read="afterRead"
					:max-size="10 * 1024 * 1024"
					@oversize="onOversize"
					:capture="camera"
				/>
				<div v-if="fileList.length">
					<img :src="fileList" alt="" />
				</div>
			</div>
			<div>(上传照片)</div>
		</div>
		<div class="info-block">
			<van-row class="i-title" type="flex" align="center" justify="space-between">
				<van-col>
					<span class="info-block-title">访问人基本信息</span>
				</van-col>
			</van-row>
			<van-field
				v-model="form.name"
				required
				label="访问人姓名"
				placeholder="填写访问人姓名"
			/>
			<van-field v-model="form.phone" label="手机号码" required placeholder="填写手机号码"  :rules="[
                {
                  validator: (value) => this.defineMethods.VerifyMobile(value),
                  message: '请填写正确手机号码',
                },
              ]"/>
			<van-field
				v-model="form.IDNumber"
				label="身份证号"
				required
				placeholder="填写身份证号"
				:rules="[
                {
                  validator: (value) => this.defineMethods.IsIDCard(value),
                  message: '请填写正确身份证号',
                },
              ]"
			/>
			<van-field
				v-model="form.time"
				label="到访时间"
				required
				right-icon="arrow"
				readonly
				@click="showTime = true"
				placeholder="填写到访时间"
			/>
			<van-field
				v-model="form.reason"
				rows="1"
				:autosize="{ minHeight: 88 }"
				required
				type="textarea"
				label="到访原因"
				placeholder="填写到访原因"
			/>
		</div>
		<div class="info-block">
			<van-row class="i-title" type="flex" align="center" justify="space-between">
				<van-col>
					<span class="info-block-title">被访人信息</span>
				</van-col>
			</van-row>
			<van-field
				v-model="form.vName"
				required
				label="被访人姓名"
				placeholder="填写被访人姓名"
			/>
			<van-field
				v-model="form.vNumber"
				label="被访人电话"
				required
				placeholder="填写被访人手机号码"
				:rules="[
                {
                  validator: (value) => this.defineMethods.VerifyMobile(value),
                  message: '请填写正确手机号码',
                },
              ]"
			/>
		</div>
		<div class="a-btn">
			<van-button
				type="primary"
							:disabled="isEmpty"
				:color="defineStyle.themeColor"
				block
				native-type="submit"
				>确认</van-button
			>
			<van-button @click="$router.go(-1)" type="primary" :color="defineStyle.color.gray" block
				>取消</van-button
			>
		</div>
		<van-popup v-model="showTime" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="chooseTime"
				title="选择年月日"
				@confirm="onConfirmTime"
				@cancel="showTime = false"
			/>
		</van-popup>
		</van-form>
	</div>
</template>
<script>
import { UploadPicture } from '@/api/Upload.js'
import { Add } from '@/api/Visitor/index.js'
import { Form ,Field, RadioGroup, Radio, Button, Uploader, DatetimePicker, Popup, Toast } from 'vant'
export default {
	components: {
		[Form.name]: Form,
		[Field.name]: Field,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Button.name]: Button,
		[Uploader.name]: Uploader,
		[Popup.name]: Popup,
		[DatetimePicker.name]: DatetimePicker,
	},
	data() {
		return {
			form: {
				name: '',
				phone: '',
				IDNumber: '',
				reason: '',
				vName: '',
				vNumber: '',
				time: '',
				userPhoto: '',
			},
			showTime: false,
			chooseTime: new Date(),
			fileList: [],
			DoorUserId: 0,
			addUserCommand: null,
			addUserFaceCommand: null,
			serversCode:'100020',
			camera:"camera"
		}
	},
	computed: {
		isEmpty() {
			return Object.values(this.form).includes('')
		},
	},
	methods: {
		//图片上传之前
		beforeRead(file) {
			if (file.type !== 'image/jpeg' && file.type != 'image/png') {
				Toast('请上传 jpg,png 格式图片')
				return false
			}
			return true
		},
		//文件上传完
		async afterRead(file) {
			file.status = 'uploading'
			file.message = '上传中...'
			//创建文件对象
			var forms = new FormData()
			//追加文件对象
			forms.append('file', file.file)
			//上传文件
			var result = await UploadPicture(forms)
			debugger
			if (result.code === 200) {
				file.status = 'success'
				file.message = '上传成功'
				this.form.userPhoto = result.Data
			} else {
				file.status = 'failed'
				file.message = '上传失败' + result.msg
			}
		},
		//添加
		async Add() {
			console.log(this.form)
			let model = {
				Photo: this.form.userPhoto,
				Name: this.form.name,
				ContactPhone: this.form.phone,
				IDcard: this.form.IDNumber,
				VisitingTime: this.dayjs(this.form.time).unix(),
				VisitReason: this.form.reason,
				ToPeopleName: this.form.vName,
				ToPeoplePhone: this.form.vNumber,
			}
			let result = await Add(model)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('添加成功')
				setTimeout(() => {
					this.$router.go(-1)
				}, 1 * 1000)
			} else {
				Toast.fail('添加失败')
			}
		},
		onOversize(file) {
			console.log(file)
			Toast('文件大小不能超过 10mb')
		},
		//时间确认
		onConfirmTime(val) {
			this.form.time = this.dayjs(val).format('YYYY/MM/DD')
			this.showTime = false
		},
		//替换命令的json格式
		commnadJosn(jsonData) {
			let a = this.form.time.replace('/', '-')
			a = a.replace('/', '-')
			jsonData = jsonData.replace('#ServersCode', ''+this.serversCode+'')
			jsonData = jsonData.replace('#employeeNo', '' + this.DoorUserId + '')
			jsonData = jsonData.replace('#name', '' + this.form.name + '')
			jsonData = jsonData.replace('#userType', 'normal')
			jsonData = jsonData.replace('#enable', 'true')
			jsonData = jsonData.replace('#beginTime', '' + a + 'T00:00:01')
			jsonData = jsonData.replace('#endTime', '' + a + 'T23:59:59')
			jsonData = jsonData.replace('#timeType', 'local')
			jsonData = jsonData.replace('#doorRight', '1')
			jsonData = jsonData.replace('#doorNo', '1')
			jsonData = jsonData.replace('#planTemplateNo', '1')
			jsonData = jsonData.replace('#userVerifyMode', 'face')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
			//添加人员相关人脸
			// setTimeout(() => {
			//   let b = this.addUserFaceCommand.CommandJson;
			//   b = b.replace("#ServersCode", "100020");
			//   b = b.replace("#faceLibType", "blackFD");
			//   b = b.replace("#FDID", "1");
			//   b = b.replace("#FPID", "" + this.DoorUserId + "");
			//   b = b.replace("#ImageUrl", "" + this.form.userPhoto + "");
			//   let result2 = this.handdleMsg(b); //添加人员的命令
			//   console.log(result2);
			// }, 4 * 1000);
		},
		//websocket消息
		async handdleMsg(msg) {
			let that = this
			console.log(that.global.ws)
			if (that.global.ws && that.global.ws.readyState == 1) {
				console.log('发送信息', msg)
				that.global.ws.send(msg)
			}
			that.global.ws.onmessage = function (res) {
				console.log('收到服务器内容', res.data)
				console.log(JSON.parse(res.data).Msg)
				let result = JSON.parse(res.data).Msg

				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set User Success'
				) {
					let b = that.addUserFaceCommand.CommandJson
					b = b.replace('#ServersCode', ''+that.serversCode+'')
					b = b.replace('#faceLibType', 'blackFD')
					b = b.replace('#FDID', '1')
					b = b.replace('#FPID', '' + that.DoorUserId + '')
					b = b.replace('#ImageUrl', '' + that.form.userPhoto + '')
					that.handdleMsg(b) //添加人员的命令
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set Face Success'
				) {
					that.Add2()
				}
			}
		},
	},
	async mounted() {
		this.DoorUserId = await this.defineMethods.genID()
		console.log(this.DoorUserId)
		JSON.parse(this.defineMethods.getSessionStorage('TerminalCommandList')).forEach(
			(item, index) => {
				// debugger;
				if (item.CommandType == 5 && item.TerminalType == 4) this.addUserCommand = item
				if (item.CommandType == 7 && item.TerminalType == 4) this.addUserFaceCommand = item
			}
		)
		console.log(this.addUserCommand)
		console.log(this.addUserFaceCommand)
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.add-a {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	.a-btn {
		margin: 0 16px;
		margin-top: 24px;
		> button:last-child {
			margin-top: 12px;
		}
	}
	.u-img {
		background: #fff;
		padding: 24px 0;
		margin: 0 auto;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: $defocus;
		text-align: center;
		margin-bottom: 5px;
		> div:first-child {
			width: 107px;
			height: 107px;
			border-radius: 8px;
			background: $bgColor;
			border: 1px dashed $gray;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
		}
		> div:last-child {
			margin-top: 8px;
		}
	}
	.info-block {
		min-height: 44px;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 4px;
		background: #fff;
		padding-top: 1px;

		> .i-title {
			padding: 0 16px;
			box-sizing: border-box;
		}
		&-title {
			position: relative;
			padding-left: 10px;
			line-height: 44px;
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
		}
		&-title:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			height: 18px;
			width: 4px;
			background: $themeColor;
			border-radius: #{'4PX'};
		}
		.slot-icon {
			color: $themeColor;
			height: 52px;
			line-height: 52px;
		}
	}
	.info-block:before {
		content: '';
		position: absolute;
		box-sizing: border-box;
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: #{'1PX'} solid #ebedf0;
		transform: scaleY(0.5);
	}
}
</style>
<style lang="scss">
.add-a {
	.u-img {
		.van-uploader + div {
			display: none;
		}
		.van-uploader__preview + .van-uploader__upload {
			display: none;
		}
		.van-uploader__preview {
			margin: 0;
		}
		.van-uploader__preview-image {
			width: 107px;
			height: 107px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}
		.van-uploader__upload {
			margin: 0;
			background-color: transparent;
		}
		.van-uploader__upload-icon {
			color: #a0a0a0;
			font-size: 48px;
		}
	}
}
</style>